import QueryString from 'qs';

import type { IPost, IPostWithoutRelations } from '.';
import {
    deleteAxiosAPI,
    getAxiosAPI,
    patchAxiosAPI,
    postAxiosAPI,
    putAxiosAPI,
} from '../../lib/request';
import type { IStrapiResponse } from '../../shared/interfaces/strapi-response';

const urlPlural = '/posts';

export async function getPost<T>(
    id: number,
    queryObject: Record<string, unknown>,
    userToken?: string,
): Promise<IStrapiResponse<T>> {
    const queryString = QueryString.stringify(queryObject);
    const response = await getAxiosAPI<IStrapiResponse<T>>(
        `${urlPlural}/${id}?${queryString}`,
        userToken,
    );
    return response.data;
}

export async function getPosts<T = IPost>(
    queryObject: Record<string, unknown>,
    userToken?: string,
): Promise<IStrapiResponse<T[]>> {
    const queryString = QueryString.stringify(queryObject);
    const response = await getAxiosAPI<IStrapiResponse<T[]>>(
        `${urlPlural}?${queryString}`,
        userToken,
    );
    return response.data;
}

export async function createPost(
    data: FormData,
    userToken: string,
): Promise<IPostWithoutRelations> {
    const post = await postAxiosAPI<{ data: IPostWithoutRelations }>(
        `${urlPlural}`,
        data,
        userToken,
    );
    return post.data.data;
}

export async function editPost(
    postId: IPost['id'],
    data: FormData,
    userToken: string,
): Promise<IPostWithoutRelations> {
    const response = await putAxiosAPI<{ data: IPostWithoutRelations }>(
        `${urlPlural}/${postId}`,
        data,
        userToken,
    );
    return response.data.data;
}

export async function deletePost(postId: IPost['id'], userToken: string): Promise<void> {
    await deleteAxiosAPI(`${urlPlural}/${postId}`, userToken);
}

export async function sendTestEmail(data: FormData, userToken: string): Promise<void> {
    await postAxiosAPI(`${urlPlural}/send-test-email`, data, userToken);
}

export async function getPostsWithInsights<T = IPost>(
    queryObject: Record<string, unknown>,
): Promise<IStrapiResponse<T[]>> {
    const queryString = QueryString.stringify(queryObject);
    const response = await getAxiosAPI<IStrapiResponse<T[]>>(
        `${urlPlural}/posts-with-comments-and-likes?${queryString}`,
    );

    return response.data;
}

export async function incrementAudioStats(
    postId: IPost['id'],
    value: 'audio_streams' | 'audio_completions' extends keyof IPost
        ? 'audio_streams' | 'audio_completions'
        : never,
): Promise<void> {
    await patchAxiosAPI(`${urlPlural}/${postId}/audio/stats`, { value });
}
