import type { Routes } from '../../config/routes.interface';

export type OrderRouteKeys = 'paymentWaiting' | 'paymentSuccess' | 'paymentError' | 'chooseAPlan';

export const orderRoutes: Routes<OrderRouteKeys> = {
    paymentWaiting: {
        name: `Paiement en attente`,
        getUrl: () => '/payment/waiting',
    },
    paymentError: {
        name: `Erreur de paiement`,
        getUrl: () => '/payment/error',
    },
    paymentSuccess: {
        name: `Paiement réussi`,
        getUrl: () => '/payment/success',
    },
    chooseAPlan: {
        name: `Choisir un plan`,
        getUrl: () => '/payment/choose-a-plan',
    },
};
