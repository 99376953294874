import IconBoxArrowDownLeft from 'bootstrap-icons/icons/box-arrow-down-left.svg';
import { signOut } from 'next-auth/react';

interface LogoutButtonProps {}

export default function LogoutButton({}: LogoutButtonProps): JSX.Element {
    return (
        <button
            className="w-full flex items-center gap-3 p-3 text-xs font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
            type="button"
            onClick={() => signOut({ callbackUrl: '/' })}>
            <IconBoxArrowDownLeft />
            <span>Se déconnecter</span>
        </button>
    );
}
