import IconSpeedometer2 from 'bootstrap-icons/icons/speedometer2.svg';

import type { Routes } from '../../config/routes.interface';

export type PublicationRouteKeys =
    | 'adminPublicationDashboard'
    | 'adminPublicationCreate'
    | 'adminPublicationGet'
    | 'publicationSlug'
    | 'publicationEmbed';

export const publicationRoutes: Routes<PublicationRouteKeys> = {
    adminPublicationDashboard: {
        name: 'Tableau de bord',
        getUrl: (id) => `/admin/publication/${id}`,
        icon: <IconSpeedometer2 />,
    },
    adminPublicationCreate: {
        name: 'Créer une publication',
        getUrl: () => '/admin/publication/create',
    },
    adminPublicationGet: {
        name: 'Tableau de bord de la publication',
        getUrl: (publicationId, message) =>
            `/admin/publication/${publicationId}${message ? `?message=${message}` : ''}`,
    },
    publicationSlug: {
        name: 'Page de la Publication',
        getUrl: (slugOrId) => `/publication/${slugOrId}`,
    },
    publicationEmbed: {
        name: 'Intégration de la Publication',
        getUrl: (slug) => `/embed/${slug}`,
    },
};
