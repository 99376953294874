import IconBoxArrowUpRight from 'bootstrap-icons/icons/box-arrow-up-right.svg';
import IconCollection from 'bootstrap-icons/icons/collection.svg';
import IconMortarboard from 'bootstrap-icons/icons/mortarboard.svg';
import IconPencilSquare from 'bootstrap-icons/icons/pencil-square.svg';
import IconPeople from 'bootstrap-icons/icons/people.svg';
import IconSliders from 'bootstrap-icons/icons/sliders.svg';
import IconSpeedometer2 from 'bootstrap-icons/icons/speedometer2.svg';
import IconUiRadiosGrid from 'bootstrap-icons/icons/ui-radios-grid.svg';
import type { ReactNode } from 'react';

import LogoutButton from '../components/modules/LogoutButton';
import type { NavOptionList } from '../components/modules/Navigation/navigation.interface';
import SwitchThemeButton from '../components/modules/SwitchThemeButton';
import type { OrderRouteKeys } from '../features/order';
import { orderRoutes } from '../features/order';
import type { PostRouteKeys } from '../features/post';
import { postRoutes } from '../features/post';
import { publicationRoutes } from '../features/publication';
import type { PublicationRouteKeys } from '../features/publication';
import type { UserRouteKeys } from '../features/user';
import { userRoutes } from '../features/user';
import type { Routes } from './routes.interface';

export interface IButton {
    onClick?: () => void;
    name: string;
    icon?: ReactNode;
    subNav?: NavOptionList;
    component?: JSX.Element;
}

export interface Actions {
    [key: string]: IButton;
}

export type RouteKeys =
    | 'home'
    | 'dashboard'
    | 'adminPublication'
    | 'adminPublicationPosts'
    | 'publication'
    | 'subscriptions'
    | 'login'
    | 'magicLink'
    | 'magicLogin'
    | 'register'
    | 'discover'
    | 'help'
    | 'confidentiality'
    | 'conditions'
    | 'lostPassword'
    | 'maintenance'
    | 'confirmUser'
    | 'unsubscribe'
    | 'newPassword'
    | 'contact'
    | 'jobs'
    | 'blog'
    | 'about'
    | 'pricing'
    | 'confirmSubscription'
    | 'presentation'
    | 'functionalities'
    | 'page403'
    | 'page404'
    | 'page500'
    | 'adminPublicationSubscribers'
    | 'adminPublicationSettings'
    | 'createPost'
    | 'profileEdit'
    | 'profile'
    | 'editPost'
    | 'faq'
    | 'subscribers'
    | 'publicationEdit'
    | 'checkEmail';

const routes: Routes<
    PostRouteKeys | PublicationRouteKeys | UserRouteKeys | RouteKeys | OrderRouteKeys
> = {
    ...publicationRoutes,
    ...postRoutes,
    ...userRoutes,
    ...orderRoutes,
    home: {
        name: "Page d'accueil",
        getUrl: (errorMessage) => `/${errorMessage ? `?errorMessage=${errorMessage}` : ''}`,
    },
    dashboard: {
        name: 'Tableau de bord',
        getUrl: () => '/admin/dashboard',
        icon: <IconSpeedometer2 />,
    },
    profileEdit: {
        name: 'Paramètres utilisateur',
        getUrl: () => '/admin/profile/edit',
    },
    adminPublication: {
        name: 'Publication',
        getUrl: (publicationId) => `/admin/publication/${publicationId}`,
        icon: <IconSpeedometer2 />,
    },
    adminPublicationPosts: {
        name: 'Vos articles',
        getUrl: (publicationId, message) =>
            `/admin/publication/${publicationId}/posts${message ? `?message=${message}` : ''}`,
        icon: <IconPencilSquare />,
    },
    adminPublicationSettings: {
        name: 'Paramètres de la publication',
        getUrl: (publicationId) => `/admin/publication/${publicationId}/edit`,
        icon: <IconSliders />,
    },
    publication: {
        name: 'Page de la publication',
        getUrl: (slug) => `/publication/${slug}`,
    },
    profile: {
        name: 'Voir mon profil',
        getUrl: () => '/user/profile',
        icon: <IconBoxArrowUpRight />,
    },
    confirmSubscription: {
        name: `Confirmation d'abonnement`,
        getUrl: () => '/confirm-subscription',
    },
    subscriptions: {
        name: 'Mes abonnements',
        getUrl: () => '/admin/subscriptions',
        icon: <IconCollection />,
    },
    login: {
        name: 'Se connecter',
        getUrl: (errorMessage) => `/login${errorMessage ? `?errorMessage=${errorMessage}` : ''}`,
    },
    magicLink: {
        name: 'Lien magique',
        getUrl: () => '/magic-link',
    },
    magicLogin: {
        name: 'Connexion sans mot de passe',
        getUrl: () => '/magic-login',
    },
    register: {
        name: "S'inscrire",
        getUrl: () => '/register',
    },
    discover: {
        name: 'Découvrir des publications',
        target: '_blank',
        getUrl: () => '/#publications',
        icon: <IconUiRadiosGrid />,
    },
    help: {
        name: 'Aide et ressources',
        target: '_blank',
        getUrl: () => 'https://a-propos.partager.io/faq',
        icon: <IconMortarboard />,
    },
    confidentiality: {
        name: 'Confidentialité',
        getUrl: () => '/politique-de-confidentialite',
    },
    conditions: {
        name: 'Conditions',
        getUrl: () => '/cgu',
    },
    lostPassword: {
        name: 'Mot de passe oublié ?',
        getUrl: () => '/remind-password',
    },
    newPassword: {
        name: 'Nouveau mot de passe',
        getUrl: () => '/new-password',
    },
    maintenance: {
        name: 'En cours de maintenance',
        getUrl: () => '/maintenance',
    },
    confirmUser: {
        name: "Confirmer l'utilisateur",
        getUrl: () => '/confirm-user',
    },
    contact: {
        name: 'Contact',
        getUrl: () => '/contact',
    },
    jobs: {
        name: 'Jobs',
        getUrl: () => '/jobs',
    },
    blog: {
        name: 'Blog',
        getUrl: () => '/blog',
    },
    about: {
        name: 'À propos',
        getUrl: () => '/about',
    },
    pricing: {
        name: 'Tarifs',
        getUrl: () => '/pricing',
    },
    presentation: {
        name: 'Présentation',
        getUrl: () => '/presentation',
    },
    unsubscribe: {
        name: 'Désinscription',
        getUrl: () => '/unsubscribe',
    },
    functionalities: {
        name: 'Fonctionnalités',
        getUrl: () => '/functionalities',
    },
    page403: {
        name: 'Erreur 403',
        getUrl: () => '/403',
    },
    page404: {
        name: 'Erreur 404',
        getUrl: () => '/404',
    },
    page500: {
        name: 'Erreur 500',
        getUrl: () => '/500',
    },
    adminPublicationSubscribers: {
        name: 'Vos lecteurs',
        getUrl: (publicationSlug) => `/admin/publication/${publicationSlug}/subscribers`,
        icon: <IconPeople />,
    },
    faq: {
        name: 'FAQ',
        getUrl: () => 'https://a-propos.partager.io/faq',
    },
    subscribers: {
        name: 'Vos lecteurs',
        getUrl: (publicationId) => `/admin/publication/${publicationId}/subscribers`,
        icon: <IconPeople />,
    },
    publicationEdit: {
        name: 'Paramètres de la publication',
        getUrl: (publicationId) => `/admin/publication/${publicationId}/edit`,
        icon: <IconSliders />,
    },
    checkEmail: {
        name: 'Vérifier E-mail',
        getUrl: () => '/check-email',
    },
};

export const actions: Actions = {
    logout: {
        name: '',
        icon: <LogoutButton />,
    },
    darkMode: {
        name: '',
        icon: <SwitchThemeButton />,
    },
};

export default routes;
