function isObject(element: unknown): element is Record<string, unknown> {
    return typeof element === 'object' && !Array.isArray(element) && element !== null;
}

export default function classNames(...classes: unknown[]): string {
    const formatted: string[] = [];
    classes.forEach((classValue) => {
        if (isObject(classValue)) {
            Object.entries(classValue).forEach(([key, value]) => {
                if (value) {
                    formatted.push(key);
                }
            });
        } else if (typeof classValue === 'string') {
            if (classValue) {
                formatted.push(classValue);
            }
        }
    });
    return formatted.join(' ');
}
