import QueryString from 'qs';

import { getAxiosAPI, putAxiosAPI, postAxiosAPI } from '../../lib/request';
import type IMedia from '../../shared/interfaces/media.interface';
import type { IStrapiResponse } from '../../shared/interfaces/strapi-response';
import type { IPublication, IOffer, IPublicationWithoutRelations } from './publication.interface';

const urlSingular = '/publication';
const urlPlural = '/publications';

export async function getPublication<T = IPublication>(
    id: number,
    queryObject?: Record<string, unknown>,
    userToken?: string,
): Promise<IStrapiResponse<T>> {
    const queryString = QueryString.stringify(queryObject);
    const response = await getAxiosAPI<IStrapiResponse<T>>(
        `${urlPlural}/${id}?${queryString}`,
        userToken,
    );
    return response.data;
}

export async function getPublications<T = IPublication>(
    queryObject: Record<string, unknown>,
    userToken?: string,
): Promise<IStrapiResponse<T[]>> {
    const queryString = QueryString.stringify(queryObject, { encodeValuesOnly: true });
    const publicationResponse = await getAxiosAPI<IStrapiResponse<T[]>>(
        `${urlPlural}?${queryString}`,
        userToken,
    );
    return publicationResponse.data;
}

// TODO rework this function to fetch only what we want
export async function getMyPublications(
    userId: number,
): Promise<
    Array<IPublicationWithoutRelations & { logo: Pick<IMedia, 'url' | 'provider_metadata'> | null }>
> {
    const queryString = QueryString.stringify({
        filters: {
            state: 'published',
            user: userId,
        },
        sort: 'id:desc',
        populate: {
            logo: {
                fields: ['url', 'provider_metadata'],
            },
        },
    });
    const publicationResponse = await getAxiosAPI<
        IStrapiResponse<
            Array<
                IPublicationWithoutRelations & {
                    logo: Pick<IMedia, 'url' | 'provider_metadata'> | null;
                }
            >
        >
    >(`${urlPlural}?${queryString}`);
    return publicationResponse.data.data;
}

export async function editPublication(
    data: FormData,
    id: number,
    userToken: string,
): Promise<void> {
    await putAxiosAPI(`${urlPlural}/${id}`, data, userToken);
}

export async function createPublication(
    data: FormData | unknown,
    userToken: string,
): Promise<IPublication> {
    const response = await postAxiosAPI<IStrapiResponse<IPublication>>(
        `${urlPlural}`,
        data,
        userToken,
    );
    return response.data.data;
}

export async function getPublicationWithSubscribersCountPaginatedAndSorted<T = IPublication>(
    start: number,
    limit: number,
): Promise<IStrapiResponse<T[]>> {
    const response = await getAxiosAPI<IStrapiResponse<T[]>>(
        `/publications-with-subscribers-count-paginated-and-sorted?start=${start}&limit=${limit}&filters[isValidated]=true`,
    );

    return response.data;
}

export async function getOffersByPublication(id: number, userToken: string): Promise<IOffer[]> {
    const response = await getAxiosAPI<IOffer[]>(`${urlSingular}/offers/${id}`, userToken);
    return response.data;
}

export async function editOffers(id: number, data: unknown, userToken: string): Promise<void> {
    await postAxiosAPI(`${urlSingular}/activate-offers/${id}`, data, userToken);
}
