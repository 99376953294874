import type { AxiosResponse } from 'axios';
import axios from 'axios';

export function getStrapiURL(path = ''): string {
    return `${process.env.NEXT_PUBLIC_API_URL || 'http://localhost:1337/api'}${path}`;
}

export async function fetchAPI(path: string): Promise<unknown> {
    const requestUrl = getStrapiURL(path);
    const response = await fetch(requestUrl);
    return response.json();
}

export async function fetchAxiosAPI(path: string): Promise<AxiosResponse> {
    const requestUrl = getStrapiURL(path);
    const response = await axios.get(requestUrl);
    return response.data;
}

export async function postAxiosAPI<T = unknown>(
    path: string,
    data: unknown,
    userToken?: string,
): Promise<AxiosResponse<T>> {
    const requestUrl = getStrapiURL(path);
    const config: {
        headers?: {
            Authorization: string;
        };
    } = {};
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }
    return axios.post<T>(requestUrl, data, config);
}

export async function deleteAxiosAPI(path: string, userToken?: string): Promise<AxiosResponse> {
    const requestUrl = getStrapiURL(path);
    const config: {
        headers?: {
            Authorization: string;
        };
    } = {};
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }

    return axios.delete(requestUrl, config);
}

export async function putAxiosAPI<T = unknown>(
    path: string,
    data: unknown,
    userToken?: string,
): Promise<AxiosResponse<T>> {
    const requestUrl = getStrapiURL(path);
    const config: {
        headers?: {
            Authorization: string;
        };
    } = {};
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }
    return axios.put(requestUrl, data, config);
}

export async function patchAxiosAPI(
    path: string,
    data: unknown,
    userToken?: string,
): Promise<AxiosResponse> {
    const requestUrl = getStrapiURL(path);
    const config: {
        headers?: {
            Authorization: string;
        };
    } = {};
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }

    return axios.patch(requestUrl, data, config);
}

export async function getAxiosAPI<T = unknown>(
    path: string,
    userToken?: string,
): Promise<AxiosResponse<T>> {
    const requestUrl = getStrapiURL(path);

    const config: {
        headers?: {
            Authorization: string;
        };
    } = {};
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }

    return axios.get<T>(requestUrl, config);
}

export async function getByIdAxiosAPI(
    path: string,
    userToken?: string,
    id?: string,
): Promise<AxiosResponse> {
    const requestUrl = getStrapiURL(path);

    const config: {
        headers?: {
            Authorization: string;
        };
    } = {};
    if (userToken) {
        config.headers = { Authorization: `Bearer ${userToken}` };
    }
    return axios.get(`${requestUrl}${id}`, config);
}
