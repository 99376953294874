import QueryString from 'qs';

import { getAxiosAPI, postAxiosAPI, putAxiosAPI } from '../../lib/request';
import type { IUser } from './user.interface';

const urlPlural = '/users';

export async function getUsers<T = IUser>(queryObject: Record<string, unknown>): Promise<T[]> {
    const queryString = QueryString.stringify(queryObject, { encodeValuesOnly: true });
    const userResponse = await getAxiosAPI<T[]>(`${urlPlural}?${queryString}`);
    return userResponse.data;
}

export async function update<T = IUser>(
    data: FormData,
    userId: number,
    userJWT: string,
): Promise<T> {
    const response = await putAxiosAPI<T>(`${urlPlural}/${userId}`, data, userJWT);
    return response.data;
}

export async function signUp(email: string, password: string): Promise<void> {
    await postAxiosAPI<IUser>(`/auth/local/register`, {
        email,
        username: email,
        password,
        subscribeAtRegisterList: [],
    });
}
