import IconBoxArrowUpRight from 'bootstrap-icons/icons/box-arrow-up-right.svg';
import IconSliders from 'bootstrap-icons/icons/sliders.svg';

import type { Routes } from '../../config/routes.interface';

export type UserRouteKeys = 'getUser' | 'editUser' | 'onboardingProfile';

export const userRoutes: Routes<UserRouteKeys> = {
    getUser: {
        name: 'Profil utilisateur',
        getUrl: (slugOrId) => `/user/${slugOrId}`,
        target: '_blank',
        icon: <IconBoxArrowUpRight />,
    },
    editUser: {
        name: 'Paramètres utilisateur',
        getUrl: () => '/admin/profile/edit',
        icon: <IconSliders />,
    },
    onboardingProfile: {
        name: 'Complétez votre profil',
        getUrl: () => '/onboarding/profile',
    },
};
