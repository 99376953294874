import IconMoon from 'bootstrap-icons/icons/moon.svg';
import { useTheme } from 'next-themes';
import { useEffect, useState } from 'react';

import switchTheme from './helper';

interface SwitchThemeButtonProps {}

export default function SwitchThemeButton({}: SwitchThemeButtonProps): JSX.Element {
    const [text, setText] = useState<string>('');
    const { theme } = useTheme();
    useEffect(() => {
        setText(theme === 'dark' ? 'Mode clair' : 'Mode sombre');
    }, [theme]);

    return (
        <button
            className="w-full flex items-center gap-3 p-3 text-xs font-normal text-gray-900 dark:text-white hover:bg-gray-100 dark:hover:bg-gray-600"
            type="button"
            onClick={switchTheme}>
            <IconMoon />
            <span>{text}</span>
        </button>
    );
}
