import type { Routes } from '../../config/routes.interface';

export type PostRouteKeys = 'getPost' | 'editPost' | 'createPost' | 'getPostCustomDomain';

export const postRoutes: Routes<PostRouteKeys> = {
    getPost: {
        name: 'Article',
        getUrl: (slug, postId) => `/publication/${slug}/post/${postId}`,
    },
    getPostCustomDomain: {
        name: 'Article',
        getUrl: (postId) => `/post/${postId}`,
    },
    editPost: {
        name: 'Éditer un article',
        getUrl: (postId) => `/admin/post/edit/${postId}`,
    },
    createPost: {
        name: 'Écrire un article',
        getUrl: (publicationId) => `/admin/post/create?publicationId=${publicationId}`,
    },
};
