import QueryString from 'qs';

import { getAxiosAPI, postAxiosAPI } from '../../lib/request';
import type { IStrapiResponse } from '../../shared/interfaces/strapi-response';
import type { ISubscriberList } from '../subscriberList';
import type { IOrder, waitingEmail } from './order.interface';

const urlPlural = '/orders';

export async function getOrders<T = IOrder>(
    queryObject?: Record<string, unknown>,
): Promise<IStrapiResponse<T[]>> {
    const queryString = QueryString.stringify(queryObject);
    const response = await getAxiosAPI<IStrapiResponse<T[]>>(`${urlPlural}?${queryString}`);
    return response.data;
}

export async function postOrder<T = IOrder>(
    subscriberListId: ISubscriberList['id'],
    userEmail: Omit<string, typeof waitingEmail> | typeof waitingEmail,
    externalOrderId: string,
    testLevel?: 'orion_only' | 'salesforce_only',
): Promise<IStrapiResponse<T>> {
    const response = await postAxiosAPI<IStrapiResponse<T>>(`${urlPlural}`, {
        subscriberListId,
        userEmail,
        externalOrderId,
        testLevel,
    });
    return response.data;
}
