import type { IPublication } from '../publication';
import type { ISubscriberList } from '../subscriberList';

export interface IOrder {
    readonly id: number;
    firstName: string;
    lastName: string;
    address?: string;
    address2?: string;
    address3: string;
    address4?: string;
    zipCode: string;
    city: string;
    country: string;
    email: string;
    subscriberList: ISubscriberList;
    status: IOrderStatus;
    externalOrderId?: string;
}

export const waitingEmail = 'waiting@email.com';

// Used in the payment funnel
export interface IOrderPayment
    extends Partial<
        Pick<
            IOrder,
            | 'firstName'
            | 'lastName'
            | 'address'
            | 'address2'
            | 'address3'
            | 'address4'
            | 'zipCode'
            | 'city'
            | 'country'
            | 'externalOrderId'
        >
    > {
    email: Omit<string, typeof waitingEmail> | typeof waitingEmail;
    civility?: 'M.' | 'Mme.';
    price: ISubscriberList['price'];
    step: number;
    publication: Pick<IPublication, 'id' | 'slug'>;
    onlyPaidOffers: boolean;
}

export enum IOrderStatus {
    PENDING = 'pending',
    COMPLETED = 'completed',
    ERROR = 'error',
    INVALID_PAYMENT = 'invalidPayment',
}
